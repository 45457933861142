<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card elevation="2">
      <v-btn
        icon
        @click="setDialogs('paymaya')"
        absolute
        right
        style="top: 10px; right: 5px"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="py-3 px-4"> Paymaya </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4 px-3">
        <v-alert text class="mb-4" v-if="paymayaError">
          {{ paymayaError }}
        </v-alert>
        <div v-if="!success">
          <p class="font-italic">Select a token amount</p>
          <v-row>
            <v-col cols="4" v-for="item in pointsArray" :key="item">
              <v-card
                @click="selectAmount(item)"
                outlined
                :class="item == points ? 'border' : ''"
              >
                <v-card-text class="text-center font-weight-bold">{{
                  item.toLocaleString()
                }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-btn
            block
            depressed
            color="primary"
            :loading="paymayaLoading"
            class="mt-4 mt-sm-6"
            :disabled="!points"
            @click="handleClick"
          >
            Proceed
          </v-btn>
        </div>
        <div v-else class="text-center">
          <p>
            Click the button below to proceed to the payment gateway. The link will be
            opened in a new tab/window.
          </p>
          <v-btn block depressed color="primary" @click="openPaymaya"
            >Proceed to Payment</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    rules: formRules,
    points: "",
    pointsArray: [
      100,
      200,
      300,
      400,
      500,
      1000,
      2000,
      3000,
      4000,
      5000,
      10000,
      20000,
      30000,
      40000,
      50000,
    ],
    success: false,
    paymentLink: "",
  }),
  computed: {
    ...mapState("add_points", ["paymayaError", "paymayaLoading"]),
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setDialogs("paymaya");
      },
    },
  },
  methods: {
    ...mapActions("add_points", ["submitPaymaya"]),
    setDialogs(selectedDialog) {
      this.$emit("setDialogs", selectedDialog);
    },
    minimumPoints(val) {
      if (val >= 100 && val <= 10000) {
        return true;
      } else {
        return "Minimum of 100 and maximum of 10,000 only.";
      }
    },
    selectAmount(val) {
      this.points = val;
    },
    async handleClick() {
      if (!this.paymayaLoading) {
        const formData = new FormData();
        formData.append("token", this.points);

        const res = await this.submitPaymaya(formData);

        if (!this.paymayaError) {
          this.paymentLink = res.paymaya_url;
          this.success = true;
        }
      }
    },
    openPaymaya() {
      window.open(this.paymentLink, "_blank");
    },
  },
};
</script>

<style scoped>
.border {
  border: 2px dashed var(--v-primary-base);
}
</style>
