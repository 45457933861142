<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-card class="elevation-10">
      <div class="pa-4 pa-sm-6">
        <h3 class="font-weight-bold">Withdraw</h3>
        <div v-if="!bank_types_loading">
          <div
            v-if="
              accountStatus == 'Verified' &&
              pointsGreaterMinimum &&
              pending_request < 3
            "
          >
            <div class="grey--text mb-4 mb-sm-6 mt-2">Select an outlet</div>
            <v-sheet class="bs-bg-transparent">
              <v-btn-toggle class="d-block bs-bg-transparent outlet">
                <v-slide-group show-arrows>
                  <v-slide-item v-for="(item, i) in bank_types" :key="i">
                    <div
                      class="d-flex justify-center align-center bs-overflow-hidden"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="setDialogs('ptop', item.bank.toLowerCase())"
                            v-bind="attrs"
                            v-on="on"
                            text
                            class="light me-3"
                          >
                            <v-img
                              :src="item.image"
                              alt="League"
                              max-width="32"
                              max-height="32"
                              class="rounded-circle"
                            >
                            </v-img>
                          </v-btn>
                        </template>
                        <span>
                          {{ item.bank }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-slide-item>

                  <!-- <v-slide-item>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="setDialogs('ptop', 'gcash')"
                          :disabled="
                            accountStatus == 'Verified' && pointsGreaterMinimum
                              ? false
                              : true
                          "
                          v-bind="attrs"
                          v-on="on"
                          text
                          class="light me-3"
                        >
                          <v-img
                            src="@/assets/img/payments/gcash-logo.webp"
                            alt="League"
                            max-width="32"
                            max-height="32"
                            class="rounded-circle"
                          >
                          </v-img>
                        </v-btn>
                      </template>
                      <span>GCash</span>
                    </v-tooltip>
                  </v-slide-item> -->
                </v-slide-group>
              </v-btn-toggle>
            </v-sheet>
          </div>

          <!-- <v-alert
            type="info"
            text
            v-else-if="bank_types && bank_types.length == 0"
            class="mt-4 mt-sm-6 mb-0"
          >
            <div class="d-flex flex-column flex-lg-row justify-lg-space-between bs-gap-3">
              You don't have a registered fund account.
              <router-link
                :to="{ name: 'Account' }"
                class="white--text"
              >
                Add
              </router-link>
            </div>
          </v-alert> -->

          <v-alert
            type="info"
            text
            v-else-if="accountStatus == 'Pending'"
            class="mt-4 mt-sm-6 mb-0"
          >
            <div
              class="d-flex flex-column flex-lg-row justify-lg-space-between bs-gap-3"
            >
              You can't withdraw your tokens because your account is not yet
              verified, complete the required information and photos first.
              <router-link :to="{ name: 'Account' }" class="white--text">
                Setup
              </router-link>
            </div>
          </v-alert>

          <v-alert
            type="info"
            text
            v-else-if="accountStatus == 'Files Submitted'"
            class="mt-4 mt-sm-6 mb-0"
          >
            Due to the high influx of registrants, verification may take a
            while.
          </v-alert>

          <v-alert
            type="warning"
            icon="mdi-alert-box"
            text
            v-else-if="accountStatus == 'Verified' && !pointsGreaterMinimum"
            class="mt-4 mt-sm-6 mb-0"
          >
            You don't have enough token to withdraw. The Minimum token amount that you
            can withdraw is {{ minimumPoints }}.
          </v-alert>

          <v-alert
            type="info"
            text
            v-else-if="pending_request >= 3"
            class="mt-4 mt-sm-6 mb-0"
          >
            Maximum of 3 request. Please wait for the other request to be
            verified.
          </v-alert>
        </div>
        <div v-else>
          <div class="grey--text mb-4 mb-sm-6 mt-2">Select an outlet</div>
          <div class="d-flex bs-gap-2">
            <div class="rounded-lg overflow-hidden">
              <v-skeleton-loader
                type="image"
                width="64"
                height="64"
              ></v-skeleton-loader>
            </div>
          </div>
        </div>
        <v-alert type="info" color="blue" role="alert" text class="mb-0 mt-4">
          Cash-out transactions are from 8:00 AM to 10:00 PM only. Transactions
          beyond this time will be processed the following day.
        </v-alert>
      </div>

      <v-divider></v-divider>

      <div class="pa-4 pa-sm-6">
        <div class="d-flex justify-space-between bs-gap-3">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">History</div>
            <div class="grey--text mt-2">
              Withdrawal transaction history list.
            </div>
          </div>
          <WithdrawHistoryFilterDialog
            @filter_withdraw_history="filter_withdraw_history"
          />
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :hide-default-footer="true"
        loading-text="Loading, please wait"
        :headers="headers"
        :items="withdraw_history"
        :items-per-page="item_per_page_value"
        :loading="withdraw_history_loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :page.sync="page"
        @page-count="page_count = $event"
      >
        <template v-slot:item.status="{ item }">
          <div v-if="$vuetify.breakpoint.mdAndUp">
            <v-chip
              small
              class="font-weight-bold"
              :class="
                item.status == 'Valid'
                  ? 'green white--text'
                  : item.status == 'Invalid'
                  ? 'red white--text'
                  : 'blue white--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </div>
          <div v-else>
            <span
              :class="
                item.status == 'Valid'
                  ? 'green--text'
                  : item.status == 'Invalid'
                  ? 'red--text'
                  : 'blue--text'
              "
            >
              {{ item.status }}
            </span>
          </div>
        </template>

        <template v-slot:item.points="{ item }">
          {{ money_format(parseFloat(item.points)) }}
        </template>

        <template v-slot:item.date="{ item }">
          <span>{{ date_format(item.date) }}</span>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                @click="view_details(item)"
                class="d-none d-sm-inline"
              >
                <v-icon> mdi-card-search-outline </v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>

          <a
            href="#"
            @click.prevent="view_details(item)"
            class="d-inline d-sm-none"
          >
            View Details
          </a>
        </template>
      </v-data-table>

      <div v-if="withdraw_history && withdraw_history.length != 0">
        <v-divider></v-divider>

        <div
          class="d-flex align-center justify-space-between bs-gap-3 pa-4 pa-sm-6"
        >
          <div class="d-flex align-center bs-gap-3">
            <v-select
              :value="item_per_page_value"
              :items="item_per_page"
              outlined
              dense
              label="Items"
              :hide-details="true"
              style="width: 100px"
              @change="item_per_page_value = parseInt($event, 10)"
            ></v-select>
            <div>
              Page
              {{
                page
                  ? page
                  : "0" + "-" + item_per_page_value
                  ? item_per_page_value
                  : "0"
              }}
              of {{ page_count ? page_count : "0" }}
            </div>
          </div>
          <v-pagination
            v-model="page"
            :length="page_count"
            :total-visible="0"
          ></v-pagination>
        </div>
      </div>
    </v-card>

    <PaymayaDialog
      v-if="paymayaDialog"
      @setDialogs="setDialogs"
      @refresh_table="get_withdraw_history()"
    />
    <PtopDialog
      v-if="ptopDialog"
      :ptopDetails="ptopDetails"
      @setDialogs="setDialogs"
      @refresh_table="get_withdraw_history()"
    />

    <v-dialog v-model="withdraw_history_details_dialog" max-width="400">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-4 mb-sm-6">Withdraw Details</div>

          <div class="d-flex flex-column bs-gap-2 mb-4 mb-sm-6">
            <div class="d-flex justify-space-between">
              <span class="grey--text">Status</span>
              <span
                class="text-end"
                :class="
                  withdraw_history_details.status == 'Valid'
                    ? 'green--text'
                    : withdraw_history_details.status == 'Invalid'
                    ? 'red--text'
                    : 'blue--text'
                "
                >{{ withdraw_history_details.status }}</span
              >
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Token</span>
              <span class="text-end">
                {{ money_format(parseFloat(withdraw_history_details.points)) }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Bank</span>
              <span class="text-end">
                {{
                  withdraw_history_details.bank
                    ? withdraw_history_details.bank
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Account Name</span>
              <span class="text-end">
                {{
                  withdraw_history_details.account_name
                    ? withdraw_history_details.account_name
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Account Number</span>
              <span class="text-end">
                {{
                  withdraw_history_details.account_number
                    ? withdraw_history_details.account_number
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Requested Date</span>
              <span class="text-end">
                {{
                  withdraw_history_details.date
                    ? date_format(withdraw_history_details.date)
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Evaluated Date</span>
              <span class="text-end">
                {{
                  withdraw_history_details.evaluated_at
                    ? date_format(withdraw_history_details.evaluated_at)
                    : "-"
                }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="grey--text">Remarks</span>
              <span class="text-end">
                {{
                  withdraw_history_details.remarks
                    ? withdraw_history_details.remarks
                    : "-"
                }}
              </span>
            </div>
          </div>

          <div class="d-flex justify-end">
            <v-btn
              @click="withdraw_history_details_dialog = false"
              class="light"
              depressed
            >
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PaymayaDialog from "@/components/app/withdraw/PaymayaDialog";
import PtopDialog from "@/components/app/withdraw/PtopDialog";
import WithdrawHistoryFilterDialog from "@/components/app/withdraw/WithdrawHistoryFilterDialog";
import responseGet from "@/helpers/api_request_get";
import formatDate from "@/helpers/formatDate";

export default {
  components: {
    PaymayaDialog,
    PtopDialog,
    WithdrawHistoryFilterDialog,
  },
  data: () => ({
    ptopDialog: false,
    paymayaDialog: false,
    ptopDetails: "",
    dialogs: ["ptop", "paymaya"],
    minimumPoints: 100,

    headers: [
      { text: "Token", value: "points", class: "text-uppercase" },
      { text: "Outlet", value: "bank", class: "text-uppercase" },
      { text: "Requested Date", value: "date", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        class: "text-uppercase",
        align: "right",
      },
    ],
    sortBy: "date",
    sortDesc: true,

    page: 1,
    page_count: 0,
    item_per_page: [5, 10, 20, 40, 80],
    item_per_page_value: 10,

    withdraw_history: [],
    withdraw_history_loading: false,
    withdraw_history_details: [],
    withdraw_history_details_dialog: false,

    pending_request: 0,

    filter_date_dialog: false,
    filter_date_start: "",
    filter_date_end: "",

    bank_types: [],
    bank_types_loading: false,
  }),
  mounted() {
    this.get_bank_types();

    if (this.userData.account_verified == "Verified") {
      this.get_withdraw_history();
    }
  },
  computed: {
    ...mapState(["userData", "actualPoints", "accountStatus"]),
    pointsGreaterMinimum() {
      if (this.actualPoints >= this.minimumPoints) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async get_bank_types() {
      this.bank_types_loading = true;

      const form_data = new FormData();
      form_data.append("transaction_type", "for_withdrawal");

      const data = new URLSearchParams(form_data);
      const response = await responseGet(data, "bank_types");

      switch (response.status) {
        case 200:
          {
            this.bank_types = response.data.data;
          }
          break;
      }

      this.bank_types_loading = false;
    },

    async get_withdraw_history() {
      this.withdraw_history_loading = true;
      this.withdraw_history = [];

      const form_data = new FormData();
      form_data.append("date_start", this.filter_date_start);
      form_data.append("date_end", this.filter_date_end);

      const data = new URLSearchParams(form_data);

      const response = await responseGet(data, "cashout/history");

      switch (response.status) {
        case 200:
          {
            this.withdraw_history = response.data.records.history;
            this.pending_request = response.data.records.pending_count;
          }
          break;
      }

      this.withdraw_history_loading = false;
    },

    setDialogs(selectedDialog, ptoptype) {
      let newVal = !this[`${selectedDialog}Dialog`];

      this[`${selectedDialog}Dialog`] = newVal;

      if (ptoptype) {
        if (ptoptype == "gcash") {
          this.ptopDetails = {
            bank_id: 1,
            bank: "GCash",
          };
        }

        if (ptoptype == "paymaya") {
          this.ptopDetails = {
            bank_id: 2,
            bank: "Paymaya",
          };
        }
      }

      this.dialogs.forEach((dialog) => {
        if (dialog != selectedDialog) {
          this[`${dialog}Dialog`] = false;
        }
      });

      // this.get_withdraw_history();
    },
    date_format(theDate) {
      return formatDate(theDate);
    },
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    view_details(item) {
      this.withdraw_history_details = item;
      this.withdraw_history_details_dialog = true;
    },
    date_filter() {
      this.$refs.filter_date_dialog.save(this.filter_date);
      this.get_withdraw_history();
    },
    filter_withdraw_history(data) {
      this.filter_date_start = data.date_start;
      this.filter_date_end = data.date_end;

      this.get_withdraw_history();
    },
  },
};
</script>

<style scoped>
.outlet .v-btn {
  min-width: calc(32px + 32px) !important;
  height: calc(32px + 32px) !important;
}
</style>
