<template>
  <div>
    <v-btn :color="dialog ? 'primary' : undefined" icon small @click="dialog = true">
      <v-icon>mdi-calendar-clock</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="400">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="font-weight-bold mb-4 mb-sm-6">Filter</h3>
          <v-form>
            <div class="d-flex flex-column bs-gap-3">
              <v-menu
                v-model="date_start_menu"
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_start"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_start"
                  @input="date_start_menu = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="date_end_menu"
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_end"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :min="date_start"
                  :max="today"
                  v-model="date_end"
                  @input="date_end_menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="d-flex justify-end bs-gap-2 mt-4 mt-sm-6">
              <v-btn text @click="dialog = false">Cancel</v-btn>
              <v-btn class="primary" depressed @click="filter()">Filter</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    date_start: "",
    date_start_menu: false,
    date_end: "",
    date_end_menu: false,
  }),
  name: "WithdrawHistoryFilterDialog",
  computed: {
    today() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },
  methods: {
    filter() {
      const data = {
        date_start: this.date_start,
        date_end: this.date_end,
      };

      this.$emit("filter_withdraw_history", data);
      this.dialog = false;
    },
  },
};
</script>
